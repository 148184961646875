'use client' // Error components must be Client Components

import { Alert, Box, Button, Typography } from '@mui/material'
import Link from 'next/link'
import * as Sentry from '@sentry/nextjs'
import { useEffect } from 'react'

export default function Error({
  error,
  reset
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    // Log the error to Sentry
    Sentry.captureException(error)
  }, [error])

  return (
    <Box p={2}>
      <Alert severity="error">
        <Typography variant="h6">Sivustolla ongelmia</Typography>
        <Typography>{`Virhe: ${error.message}`}</Typography>
      </Alert>
      <Typography sx={{ mt: 1 }} variant="body1">
        Yritä ladata sivu uudelleen.
      </Typography>
      <Button
        sx={{ mt: 2 }}
        variant="outlined"
        onClick={
          // Attempt to recover by trying to re-render the segment
          () => reset()
        }
      >
        Yritä uudelleen
      </Button>
      <Typography sx={{ mt: 3 }} variant="body1">
        Jos ongelma toistuu, raportoi virhe.
      </Typography>
      <Box>
        <Link href="/info">
          <Button sx={{ mt: 2 }}>Raportoi virhe</Button>
        </Link>
      </Box>
    </Box>
  )
}
